import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
  Tabs,
  Tooltip,
} from "antd";
import { Contract, ethers, isAddress } from "ethers";
import moment from "moment";
import { QRCodeCanvas } from "qrcode.react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  addErc20TokenApi,
  claimAssetApi,
  getSupportAssetsApi,
  getWalletAddressByChainApi,
  getWalletBalanceApi,
  getWalletChangeHistoryApi,
  sendAssetApi,
  validateTwitterUserApi,
} from "../../../api";
import { ReactComponent as AddIcon } from "../../../assets/balance/add_circle.svg";
import { ReactComponent as BalanceIcon } from "../../../assets/balance/balance-icon.svg";
import { ReactComponent as ClaimIcon } from "../../../assets/balance/claim.svg";
import DefaultTokenIcon from "../../../assets/balance/default_token.png";
import { ReactComponent as DepositIcon } from "../../../assets/balance/deposit.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/balance/expand_more.svg";
import { ReactComponent as SendIcon } from "../../../assets/balance/send.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/balance/twitter.svg";
import { ReactComponent as WalletIcon } from "../../../assets/balance/wallet.svg";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { ReactComponent as OutLink } from "../../../assets/out_link.svg";
import { DEPOSIT_CURSOR_KEY, notify } from "../../../utils";
import NoAssetLight from "../../../assets/balance/no asset-light.png";
import NoAssetDark from "../../../assets/balance/no assets-dark.png";
import ConnectWallet from "../../../assets/balance/connectWallet.png";
import ClaimABI from "../../../utils/ABI/claim.json";
import ClaimABIStarknet from "../../../utils/ABI/claimStarknet.json";
import { Context } from "../../../utils/context";
import { shortHash } from "../../../utils/cryptoCoins";
import { connectArgentX } from "../../../utils/account";
import { Contract as starknetContract } from "starknet";
function SendItem({
  item,
  remove,
  update,
}: {
  item: any;
  remove: () => void;
  update: (item: any) => void;
}) {
  const [address, setAddress] = useState<string>("");
  const [count, setCount] = useState<number>();
  const [addressStatus, setAddressStatus] = useState<any>("");
  const [countStatus, setCountStatus] = useState<any>("");

  const validateTwitterOrAddress = async () => {
    setCountStatus(!count ? "error" : "");
    let status = true;
    if (item.type === "wallet") {
      status = isAddress(address);
    }
    if (item.type === "twitter") {
      try {
        const res = await validateTwitterUserApi({ username: address });
        status = !!res.data.username;
      } catch (error) {
        status = false;
      }
    }
    setAddressStatus(!status ? "error" : "");
  };

  useEffect(() => {
    update({ ...item, address, count, addressStatus, countStatus });
  }, [address, count, addressStatus, countStatus]);

  return (
    <div className="send-item">
      <Input
        className="address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        status={addressStatus}
        onBlur={validateTwitterOrAddress}
        prefix={
          item.type === "wallet" ? (
            <WalletIcon />
          ) : (
            <>
              <TwitterIcon />@
            </>
          )
        }
      />
      <InputNumber
        className="count"
        value={count}
        controls={false}
        status={countStatus}
        formatter={(value) => Number(value)?.toString()}
        onChange={(e) => setCount(e || undefined)}
        onBlur={() => setCountStatus(!count ? "error" : "")}
      />
      <CloseIcon className="remove" onClick={remove} />
    </div>
  );
}

function Balance({
  theme,
  walletType,
  connectWallet,
}: {
  theme: string;
  walletType: string;
  connectWallet: () => void;
}) {
  const [balanceOpen, setBalanceOpen] = useState<boolean>(false);
  const [depositOpen, setDepositOpen] = useState<boolean>(false);
  const [sendOpen, setSendOpen] = useState<boolean>(false);
  const [ClaimOpen, setClaimOpen] = useState<boolean>(false);
  const [depositAddress, setDepositAddress] = useState<string>("");
  const [sendAsset, setSendAsset] = useState<API.CoinBalance>();
  const [sendTargets, setSendTargets] = useState<any[]>([
    { key: moment().unix(), address: "", count: null, type: "wallet" },
  ]);
  const [balanceList, setBalanceList] = useState<API.CoinBalance[]>([]);
  const [walletHistory, setWalletHistory] = useState<API.WalletHistory[]>([]);
  const [pendingInfo, setPendingInfo] = useState<any>();
  const [networkList, setNetworkList] = useState<API.NetworkAndAssets[]>([]);
  const [selectedNetwork, setSelectedNetwork] = useState<string>();
  const [depositAssetId, setDepositAssetId] = useState<number>();
  const [claimNetwork, setClaimNetwork] = useState<string>();
  const [claimAssetId, setClaimAssetId] = useState<number>();
  const [showAddToken, setShowAddToken] = useState<boolean>(false);
  const [tokenContract, setTokenContract] = useState<string>("");
  const [sendDisabled, setSendDisabled] = useState<boolean>(true);
  const [tipsTitle, setTipsTitle] = useState<string>();
  const [allTokens, setAllTokens] = useState<any[]>([]);
  const [filteredTokens, setFilteredTokens] = useState<any[]>([]);
  const [tokenNetworks, setTokenNetworks] = useState<API.NetworkAndAssets[]>(
    []
  );
  const [filteredBalanceList, setFilteredBalanceList] = useState<
    API.CoinBalance[]
  >([]);
  const context = useContext(Context);
  const { userInfo } = context;
  const backHandler = () => {
    setDepositOpen(false);
    setClaimOpen(false);
    setSendOpen(false);
    setShowAddToken(false);
    setTipsTitle("");
    setBalanceOpen(true);
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(depositAddress);
  };

  const getAddress = async () => {
    if (!selectedNetwork || !depositAssetId) {
      return;
    }
    const res = await getWalletAddressByChainApi({
      network: selectedNetwork,
      asset_id: depositAssetId,
    });
    setDepositAddress(res.data.address);
  };

  const fetchBalanceList = async () => {
    const res = await getWalletBalanceApi();
    const list = res.data;
    setBalanceList(list);
    setFilteredBalanceList(list);
    setSendAsset(list.filter((b) => !!Number(b.balance))[0]);
  };

  const fetchWalletHistory = useCallback(async () => {
    const res = await getWalletChangeHistoryApi();
    if (!res.data) {
      return;
    }
    const list = res.data;
    setWalletHistory(list);

    // check deposit
    const timeCursor = Number(localStorage.getItem(DEPOSIT_CURSOR_KEY));
    for (const i of list) {
      if (i.timestamp < timeCursor) {
        break;
      }
      if (i.record_type === "Deposit" && i.timestamp > timeCursor) {
        notify({
          type: "success",
          message: "Assets deposited Successfully",
          description: `Deposit ${
            i.value
          } ${i.coin_symbol.toUpperCase()} to your balance ${moment
            .unix(i.timestamp)
            .format("YYYY/MM/DD HH:mm:ss")}`,
        });
        localStorage.setItem(DEPOSIT_CURSOR_KEY, list[0]?.timestamp.toString());
        fetchBalanceList();
      }
    }

    // check pending
    if (pendingInfo?.key) {
      const pendingHistory = list.find(
        (i) =>
          i.timestamp === pendingInfo.timestamp && i.record_type === "Claim"
      );
      if (pendingHistory?.state === "Success") {
        notification.close(pendingInfo.key);
        setPendingInfo({});
        notify({
          type: "success",
          message: "Assets claimed Successfully",
          description: `Claim ${
            pendingInfo.amount
          } ${pendingInfo.coin_symbol.toUpperCase()} to your balance ${moment
            .unix(pendingInfo.timestamp)
            .format("YYYY/MM/DD HH:mm:ss")}`,
        });
        return;
      }
      if (pendingHistory?.state === "Failed") {
        notification.close(pendingInfo.key);
        setPendingInfo({});
        notify({
          type: "error",
          message: "Claim Failed",
          description: `Deposit ${
            pendingInfo.amount
          } ${pendingInfo.coin_symbol.toUpperCase()} to your balance ${moment
            .unix(pendingInfo.timestamp)
            .format("YYYY/MM/DD HH:mm:ss")}`,
        });
        return;
      }
    }
  }, [pendingInfo]);

  const openTx = (item: API.WalletHistory) => {
    const browser = networkList.find(
      (n) => n.network === item.network
    )?.browser;
    window.open(`${browser}tx/${item.tx_hash}`);
  };

  const sendTargetsChecker = () => {
    // check send target
    let checker = true;
    sendTargets.forEach((target) => {
      if (target.addressStatus || target.countStatus) {
        checker = false;
      }
      if (!target.address || !target.count) {
        checker = false;
      }
    });
    if (!checker || sendTargets.length === 0 || !sendAsset) {
      return;
    }
    // check asset balance
    const assetBalance = Number(sendAsset?.balance);
    const sendBalance = sendTargets.reduce((pre, cur) => {
      return pre + cur.count;
    }, 0);
    if (sendBalance > assetBalance) {
      notify({
        type: "warning",
        message: "Insufficient balance",
        description: "The balance in your wallet is insufficient",
      });
      return;
    }
    setSendDisabled(false);
  };

  const sendAssetHandler = async () => {
    // check send target
    let checker = true;
    sendTargets.forEach((target) => {
      if (target.addressStatus || target.countStatus) {
        checker = false;
      }
      if (!target.address || !target.count) {
        checker = false;
      }
    });
    if (!checker || sendTargets.length === 0 || !sendAsset) {
      notify({
        type: "warning",
        message: "Invalid recipient",
        description: "Please check the addresses or the twitter accounts.",
      });
      return;
    }
    // check asset balance
    const assetBalance = Number(sendAsset?.balance);
    const sendBalance = sendTargets.reduce((pre, cur) => {
      return pre + cur.count;
    }, 0);
    if (sendBalance > assetBalance) {
      notify({
        type: "warning",
        message: "Insufficient balance",
        description: "The balance in your wallet is insufficient",
      });
      return;
    }
    // send
    try {
      const res = await sendAssetApi({
        asset_id: sendAsset.asset_id,
        recver_data: sendTargets.map((t) => ({
          data: t.address,
          value: t.count,
        })),
      });
      if (res.errno === 0) {
        backHandler();
        setSendTargets([]);
        return;
      }
      notify({
        type: "warning",
        message: "Send Failed",
        description: res.errmsg,
      });
    } catch (error: any) {
      notify({
        type: "warning",
        message: "Send Failed",
        description: error.response?.data.errmsg,
      });
    }
  };

  const claimAsset = async (wallet: any) => {
    if (!claimNetwork || !claimAssetId) {
      return;
    }
    // trigger wallet
    const accounts = await wallet
      .request({ method: "eth_requestAccounts" })
      .catch((err: any) => {
        if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          console.log("Please connect to MetaMask.");
        } else {
          console.error(err);
        }
      });
    const account = accounts[0];
    // const primaryWallets = onboard.state.get().wallets;
    // if (!primaryWallets.length) {
    //   setClaimOpen(false);
    //   onboard.connectWallet();
    //   return;
    // }

    // const accounts = primaryWallets[0].accounts;
    const asset = balanceList.find((i) => i.asset_id === claimAssetId);

    const data: API.ClaimAssetBody = {
      network: claimNetwork,
      asset_id: claimAssetId,
      recv_addr: account,
      amount: Number(asset?.balance),
      timestamp: new Date().getTime(),
      sign: "",
    };
    if (!asset || !data.recv_addr || asset?.balance === "0") {
      return;
    }
    // // check address
    // const wallet = userInfo.extra.login_options.find(
    //   (i) => i.platform === "eth"
    // );
    // if (!wallet) {
    //   notify({
    //     type: "warning",
    //     message: "Wallet not connected",
    //     description: "Please connect your wallet first to claim",
    //   });
    //   return;
    // }
    // if (wallet && wallet.platform_id !== accounts[0].address) {
    //   notify({
    //     type: "warning",
    //     message: "Invalid wallet address",
    //     description:
    //       "Please switch to the wallet that already bind to your account.",
    //   });
    //   return;
    // }
    // check chain id
    try {
      const networkChain = networkList.find((n) => n.network === claimNetwork);
      const networkChainId = networkChain?.chain_id;
      const ChainId = `0x${Number(networkChainId)?.toString(16)}`;
      // const chainId = primaryWallets[0].chains[0].id;
      const chainId = await wallet.request({ method: "eth_chainId" });

      if (chainId !== ChainId) {
        await wallet.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: ChainId }],
        });
      }
    } catch (err: any) {
      if (err.code === 4902) {
        const networkChain = networkList.find(
          (n) => n.network === claimNetwork
        );
        notify({
          type: "warning",
          message: "",
          description: `Please adding the chain: ${networkChain?.name} on your wallet`,
        });
      }
      return;
    }
    // get contract address and signature
    try {
      const provider = new ethers.BrowserProvider(wallet);
      const signer = await provider.getSigner();
      // sign message
      const message = `${data.network}#${data.asset_id}#${data.recv_addr}#${data.amount}#${data.timestamp}`;
      data.sign = await signer.signMessage(message);

      const res = await claimAssetApi(data);
      if (!res.data.contract_addr) {
        console.error("API error: ", res);
        return;
      }
      // init contract
      const { sign, token_addr, contract_addr, amount, under_block, nonce } =
        res.data;
      const contract = new Contract(contract_addr, ClaimABI, signer);

      // transaction
      const tx = await contract.claimTokens(
        token_addr,
        amount,
        nonce,
        under_block,
        sign
      );
      backHandler();
      const key = `claim ${data.timestamp}`;
      const networkChain = networkList.find((n) => n.network === claimNetwork);
      notify({
        key,
        holding: true,
        type: "pending",
        message: "Pending...",
        description: `Claim ${
          data.amount
        } ${asset.asset_symbol.toUpperCase()} to ${shortHash(account)} ${moment(
          data.timestamp
        ).format("YYYY/MM/DD HH:mm:ss")}`,
        onClick() {
          window.open(`${networkChain?.browser}tx/${tx.hash}`);
        },
      });
      setPendingInfo({
        ...data,
        coin_symbol: asset.asset_symbol,
        key,
        timestamp: res.data.timestamp,
      });
    } catch (error: any) {
      backHandler();
      if (error.response?.data.errno === 810004) {
        notify({
          type: "cancel",
          message: "Claim Canceled",
          description: "Generate the signature error, please try again later.",
        });
        return;
      }
      notify({
        type: "cancel",
        message: "Claim Canceled",
        description: error.info ? error.info.error.message : error.message,
      });
    }
  };
  const claimAssetStarknet = async () => {
    if (!claimNetwork || !claimAssetId) {
      return;
    }
    // trigger wallet

    const starknetObject = (window as any).starknetObject;

    const asset = balanceList.find((i) => i.asset_id === claimAssetId);

    const data: API.ClaimAssetBody = {
      network: claimNetwork,
      asset_id: claimAssetId,
      recv_addr: starknetObject?.account.address || "",
      amount: Number(asset?.balance),
      timestamp: new Date().getTime(),
      sign: "",
    };
    if (!asset || !data.recv_addr || asset?.balance === "0") {
      return;
    }

    try {
      const res = await claimAssetApi(data);
      if (!res.data.contract_addr) {
        console.error("API error: ", res);
        return;
      }
      // init contract
      const {
        sign,
        sign2,
        token_addr,
        contract_addr,
        amount,
        under_block,
        nonce,
      } = res.data as any;
      const contract = new starknetContract(
        ClaimABIStarknet,
        contract_addr,
        starknetObject?.account
      );
      // console.log(contract);
      // transaction
      const tx = await contract.claimTokens(
        token_addr,
        amount,
        nonce,
        under_block,
        {
          sig1: sign,
          sig2: sign2,
        }
      );
      backHandler();
      const key = `claim ${data.timestamp}`;
      const networkChain = networkList.find((n) => n.network === claimNetwork);
      notify({
        key,
        holding: true,
        type: "pending",
        message: "Pending...",
        description: `Claim ${
          data.amount
        } ${asset.asset_symbol.toUpperCase()} to ${shortHash(
          starknetObject?.account.address || ""
        )} ${moment(data.timestamp).format("YYYY/MM/DD HH:mm:ss")}`,
        onClick() {
          window.open(`${networkChain?.browser}tx/${tx.hash}`);
        },
      });
      setPendingInfo({
        ...data,
        coin_symbol: asset.asset_symbol,
        key,
        timestamp: res.data.timestamp,
      });
    } catch (error: any) {
      console.log(error);
      backHandler();
      if (error.response?.data.errno === 810004) {
        notify({
          type: "cancel",
          message: "Claim Canceled",
          description: "Generate the signature error, please try again later.",
        });
        return;
      }
      notify({
        type: "cancel",
        message: "Claim Canceled",
        description: error.info ? error.info.error.message : error.message,
      });
    }
  };

  const getSupportAssets = async () => {
    const res = await getSupportAssetsApi();
    setNetworkList(res.data);
    const assetMap: any = {};
    res.data.forEach((n) => {
      n.asset_list.forEach((a) => {
        const net = { ...n };
        if (!assetMap[a.asset_id]) {
          assetMap[a.asset_id] = { ...a, networkList: [net] };
        } else {
          assetMap[a.asset_id] = {
            ...a,
            networkList: [...assetMap[a.asset_id].networkList, net],
          };
        }
      });
    });
    const tokens = Object.values(assetMap);
    setAllTokens(tokens);
    setFilteredTokens(tokens);
  };

  const addToken = async () => {
    if (!selectedNetwork || !tokenContract) {
      return;
    }
    if (!isAddress(tokenContract)) {
      notify({
        type: "warning",
        message: "Invalid contract address",
        description: "Please check the contract address",
      });
      return;
    }
    const res = await addErc20TokenApi({
      network: selectedNetwork,
      contract_addr: tokenContract,
    });
    if (res.errno === 0) {
      setShowAddToken(false);
      setSelectedNetwork(undefined);
      backHandler();
      getSupportAssets();
    } else {
      notify({
        type: "warning",
        message: "Add Token Failed",
        description: res.errmsg,
      });
    }
  };

  const renderSendList = (info: API.WalletHistory) => {
    if (info.record_type !== "Send" && info.record_type !== "Receive") {
      return null;
    }
    const formatList =
      info.record_type === "Send"
        ? info.extra_info?.send_list?.map((i) =>
            isAddress(i) ? shortHash(i) : `@${i}`
          )
        : [`@${info.extra_info?.sender_name}`];
    const str = formatList?.join(",");
    const title = formatList?.map((i, k) => (
      <div>
        {i}
        {k < formatList.length - 1 && ","}
      </div>
    ));

    return (
      <Tooltip
        className="send-list"
        placement="topLeft"
        title={title}
        color={theme === "dark" ? "#fff" : "#434343"}
      >
        {str}
      </Tooltip>
    );
  };

  useEffect(() => {
    getSupportAssets();
    fetchWalletHistory();
    fetchBalanceList();
    const timer = setInterval(fetchWalletHistory, 1000 * 5);
    localStorage.setItem(DEPOSIT_CURSOR_KEY, moment().unix().toString());

    return () => {
      clearInterval(timer);
    };
  }, [fetchWalletHistory]);

  useEffect(() => {
    if (balanceOpen) {
      fetchBalanceList();
    }
  }, [balanceOpen]);

  useEffect(() => {
    sendTargetsChecker();
  }, [sendTargets]);

  const tabList = [
    {
      label: "Assets",
      key: "assets",
      children: (
        <div className="assets">
          {balanceList.map((c) => (
            <div className="coin" key={c.asset_id}>
              <div className="coin-name">
                <img
                  className="icon"
                  src={c.asset_icon || DefaultTokenIcon}
                  alt=""
                />
                {c.asset_symbol.toUpperCase()}
              </div>
              <div className="coin-value">
                {Number(c.balance).toFixed(2)}
                {c.asset_price > 0 && (
                  <span>
                    ($
                    {(Number(c.balance) * c.asset_price).toFixed(2)})
                  </span>
                )}
              </div>
            </div>
          ))}
          {balanceList.length === 0 && <div className="no-asset">No Asset</div>}
        </div>
      ),
    },
    {
      label: "History",
      key: "history",
      children: (
        <div className="history assets">
          {walletHistory.map((c, i) => (
            <div
              className={`history-item ${c.record_type}`}
              key={i + c.state + c.timestamp}
            >
              <div className="date">
                {moment.unix(c.timestamp).format("YYYY/MM/DD HH:mm:ss")}
              </div>
              <div className="type">
                <div className="type-label">
                  {c.record_type}
                  <span className={`state ${c.state}`}>{c.state}</span>
                </div>
                <div className="hash">
                  {shortHash(c.tx_hash)}
                  {c.tx_hash && (
                    <OutLink className="open-tx" onClick={() => openTx(c)} />
                  )}
                  {renderSendList(c)}
                </div>
              </div>
              <div className="record">
                <div className="record-coin">
                  <div className="coin-name">
                    <img
                      className="icon"
                      src={c.asset_info.icon || DefaultTokenIcon}
                      alt=""
                    />
                    {c.coin_symbol.toUpperCase()}
                  </div>
                  <div className="value">
                    {c.record_type === "Deposit" ||
                    c.record_type === "Claim Return"
                      ? "+"
                      : ""}
                    {Number(c.value).toFixed(2)}
                  </div>
                </div>
                {/* <div className="dollar">
                  ($
                  {(
                    Math.abs(Number(c.value)) * c.asset_price
                  ).toFixed(2)}
                  )
                </div> */}
              </div>
            </div>
          ))}
          {walletHistory.length === 0 && (
            <div className="no-asset">No History</div>
          )}
        </div>
      ),
    },
  ];

  const balanceOperateList = [
    {
      name: "Deposit",
      icon: <DepositIcon />,
      click: () => {
        const list = allTokens.find((a) => a.asset_id === 1)?.networkList;
        setDepositOpen(true);
        setBalanceOpen(false);
        setDepositAssetId(1);
        setTokenNetworks(list);
        setSelectedNetwork(list[0].network);
      },
    },
    {
      name: "Send",
      icon: <SendIcon />,
      click: () => {
        if (balanceList.length === 0) {
          setTipsTitle("send");
          setBalanceOpen(false);
          return;
        }
        setSendOpen(true);
        setBalanceOpen(false);
      },
    },
    {
      name: "Claim",
      icon: <ClaimIcon />,
      click: () => {
        // const wallet = userInfo.extra.login_options.find(
        //   (i) => i.platform === "eth"
        // );
        if (!walletType) {
          setTipsTitle("wallet");
          setBalanceOpen(false);
          return;
        }
        if (balanceList.length === 0) {
          setTipsTitle("claim");
          setBalanceOpen(false);
          return;
        }
        setClaimOpen(true);
        setBalanceOpen(false);

        // set default token and network
        const value = balanceList[0]?.asset_id;
        const list = allTokens.find((t) => t.asset_id === value).networkList;
        setClaimAssetId(value);
        setTokenNetworks(list);
        setClaimNetwork(list[0].network);
      },
    },
  ];

  useEffect(() => {
    (window as any).openBalance = () => {
      setBalanceOpen(true);
      setTokenNetworks([]);
      setClaimAssetId(undefined);
      setDepositAssetId(undefined);
      setDepositAddress("");
      setClaimNetwork(undefined);
      setSelectedNetwork(undefined);
      setTokenContract("");
      setShowAddToken(false);
    };
  }, []);
  return (
    <div className="balance">
      <div
        className="balance-button"
        onClick={() => {
          setBalanceOpen(true);
          setTokenNetworks([]);
          setClaimAssetId(undefined);
          setDepositAssetId(undefined);
          setDepositAddress("");
          setClaimNetwork(undefined);
          setSelectedNetwork(undefined);
          setTokenContract("");
          setShowAddToken(false);
        }}
      >
        <BalanceIcon />
        <span className="label">assets</span>
      </div>

      {/* tips */}
      <Modal
        open={!!tipsTitle}
        onCancel={() => setTipsTitle(undefined)}
        closeIcon={<CloseIcon />}
        footer={null}
        width={440}
      >
        <div className="modal-title">
          {tipsTitle !== "send" ? "Claim" : "Send"}
        </div>
        <div className="tips-modal">
          <div className="img">
            <img
              src={
                tipsTitle === "wallet"
                  ? ConnectWallet
                  : theme === "dark"
                  ? NoAssetDark
                  : NoAssetLight
              }
              alt=""
            />
          </div>
          <div className="tips">
            {tipsTitle === "wallet" && "Please connect your wallet first"}
            {tipsTitle === "claim" && "No claimable assets"}
            {tipsTitle === "send" &&
              "Please deposit assets before sending to other people"}
          </div>
          <div className="modal-buttons">
            {tipsTitle === "wallet" ? (
              <Button
                className="deposit-btn copy"
                onClick={() => {
                  setTipsTitle(undefined);
                  connectWallet();
                }}
              >
                Connect Wallet
              </Button>
            ) : (
              <Button className="deposit-btn back" onClick={backHandler}>
                Back
              </Button>
            )}
          </div>
        </div>
      </Modal>

      {/* send */}
      <Modal
        open={sendOpen}
        onCancel={() => setSendOpen(false)}
        closeIcon={<CloseIcon />}
        footer={null}
        width={440}
      >
        <div className="modal-title">Send</div>
        <div className="send-modal">
          <div className="item">
            <div className="label">Asset</div>
            <div className="value">
              <Select
                style={{ width: 346, height: 53 }}
                value={sendAsset?.asset_id}
                onChange={(value) =>
                  setSendAsset(balanceList.find((c) => c.asset_id === value))
                }
                suffixIcon={<ExpandIcon />}
                options={balanceList
                  .filter((b) => !!Number(b.balance))
                  .map((c) => ({
                    value: c.asset_id,
                    label: (
                      <div className="coin-select-item">
                        <img
                          className="coin-icon"
                          src={c.asset_icon || DefaultTokenIcon}
                          alt=""
                        />
                        <span className="coin-name">
                          {c.asset_symbol.toUpperCase()}
                        </span>
                        <span className="amount">
                          ({Number(c.balance).toFixed(2)})
                        </span>
                      </div>
                    ),
                  }))}
              />
            </div>
          </div>
          <div className="item">
            <div className="label">To</div>
            <div className="value">
              {sendTargets.map((target) => (
                <SendItem
                  key={target.key}
                  item={target}
                  update={(newTarget) =>
                    setSendTargets(
                      sendTargets.map((t) => {
                        if (t.key === target.key) {
                          return newTarget;
                        }
                        return t;
                      })
                    )
                  }
                  remove={() =>
                    setSendTargets(
                      sendTargets.filter((t) => t.key !== target.key)
                    )
                  }
                />
              ))}
              <div className="add-send-target">
                <div
                  className="add-button"
                  onClick={() =>
                    setSendTargets([
                      ...sendTargets,
                      { key: moment().unix(), type: "twitter" },
                    ])
                  }
                >
                  <AddIcon />
                  Twitter Account
                </div>
                <span>or</span>
                <div
                  className="add-button"
                  onClick={() =>
                    setSendTargets([
                      ...sendTargets,
                      { key: moment().unix(), type: "wallet" },
                    ])
                  }
                >
                  <AddIcon />
                  Wallet Address
                </div>
              </div>
            </div>
          </div>
          <div className="modal-buttons">
            <Button className="deposit-btn back" onClick={backHandler}>
              Back
            </Button>
            <Button
              className="deposit-btn copy"
              disabled={sendDisabled}
              onClick={sendAssetHandler}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>

      {/* claim */}
      <Modal
        open={ClaimOpen}
        onCancel={() => setClaimOpen(false)}
        closeIcon={<CloseIcon />}
        footer={null}
        width={440}
      >
        <div className="modal-title">Claim</div>
        <div className="claim-modal">
          <div className="tips-title">Select asset to claim</div>
          <div className="select token">
            <div className="select-label">Select Token</div>
            <div className="value">
              <Select
                value={claimAssetId}
                onChange={(value) => {
                  setClaimAssetId(value);
                  setTokenNetworks(
                    allTokens.find((t) => t.asset_id === value).networkList
                  );
                }}
                suffixIcon={<ExpandIcon />}
                placeholder="Please select token"
                options={filteredBalanceList.map((c) => {
                  const assetBalance = Number(c.balance);
                  return {
                    value: c.asset_id,
                    balance: assetBalance,
                    label: (
                      <div className="coin-select-item large">
                        <img
                          className="coin-icon"
                          src={c.asset_icon || DefaultTokenIcon}
                          alt=""
                        />
                        <div className="coin-name">
                          <span className="symbol">
                            {c.asset_symbol.toUpperCase()}
                          </span>
                          <span className="name">({c.asset_name})</span>
                        </div>
                        <div className="amount">{assetBalance.toFixed(2)}</div>
                      </div>
                    ),
                  };
                })}
                dropdownRender={(menu) => (
                  <>
                    <Input
                      className="search-token"
                      placeholder="Enter the token symbol"
                      prefix={<SearchOutlined />}
                      onChange={(value) => {
                        const key = value.target.value;
                        if (!key) {
                          setFilteredBalanceList(balanceList);
                          return;
                        }
                        setFilteredBalanceList(
                          balanceList.filter((a) =>
                            a.asset_symbol
                              .toLowerCase()
                              .includes(key.toLowerCase())
                          )
                        );
                      }}
                    />
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )}
              />
            </div>
          </div>
          <div className="select network">
            <div className="select-label">Select Network</div>
            <div className="value">
              <Select
                suffixIcon={<ExpandIcon />}
                value={claimNetwork}
                placeholder="Please select network"
                onChange={(value) => {
                  setClaimNetwork(value);
                }}
                options={tokenNetworks.map((c) => ({
                  value: c.network,
                  label: (
                    <div className="coin-select-item">
                      <img className="coin-icon" src={c.icon} alt="" />
                      <span className="coin-name">{c.name}</span>
                    </div>
                  ),
                }))}
              />
            </div>
          </div>
          <div className="modal-buttons">
            <Button className="deposit-btn back" onClick={backHandler}>
              Back
            </Button>
            <Button
              className="deposit-btn copy"
              onClick={() => {
                console.log(claimNetwork, walletType);
                if (claimNetwork === "starknet_goerli") {
                  if (walletType === "argent") claimAssetStarknet();
                  else
                    notify({
                      type: "warning",
                      message: "",
                      description:
                        "Network not supported,please switch your wallet",
                    });
                } else {
                  if (walletType === "metamask")
                    claimAsset((window as any).ethereum);
                  else if (walletType === "okx")
                    claimAsset((window as any).okxwallet);
                  else
                    notify({
                      type: "warning",
                      message: "",
                      description:
                        "Network not supported,please switch your wallet",
                    });
                }
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>

      {/* deposit */}
      <Modal
        open={depositOpen}
        onCancel={() => setDepositOpen(false)}
        closeIcon={<CloseIcon />}
        footer={null}
        width={440}
      >
        <div className="modal-title">Deposit</div>
        {depositAddress ? (
          <div className="deposit-modal">
            <div className="qrCode">
              <QRCodeCanvas value={depositAddress} />
            </div>
            {/* <div className="tips">
              Lorem ipsum dolor sit amet, consectetur ed adipiscing elit, sed do
              eiusmod tempor
            </div> */}
            <div className="label">Deposit Address</div>
            <div className="address">{depositAddress}</div>
            <div className="modal-buttons">
              <Button
                className="deposit-btn back"
                onClick={() => {
                  setDepositAddress("");
                }}
              >
                Back
              </Button>
              <Button className="deposit-btn copy" onClick={copyAddress}>
                Copy Address
              </Button>
            </div>
          </div>
        ) : (
          <div className="deposit-modal">
            {showAddToken ? (
              <>
                <div className="select network">
                  <div className="select-label">Select Network</div>
                  <div className="value">
                    <Select
                      suffixIcon={<ExpandIcon />}
                      value={selectedNetwork}
                      placeholder="Please select network"
                      onChange={(value) => {
                        setSelectedNetwork(value);
                      }}
                      options={networkList.map((c) => ({
                        value: c.network,
                        label: (
                          <div className="coin-select-item">
                            <img className="coin-icon" src={c.icon} alt="" />
                            <span className="coin-name">{c.name}</span>
                          </div>
                        ),
                      }))}
                    />
                  </div>
                </div>
                <div className="select contract">
                  <div className="select-label">Contract Address</div>
                  <div className="value">
                    <Input
                      className="contract-input"
                      value={tokenContract}
                      onChange={(e) => setTokenContract(e.target.value)}
                      placeholder="Paste ERC20 token contract address"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="select token">
                  <div className="select-label">Select Token</div>
                  <div className="value">
                    <Select
                      value={depositAssetId}
                      onChange={(value) => {
                        setDepositAssetId(value);
                        setTokenNetworks(
                          allTokens.find((a) => a.asset_id === value)
                            ?.networkList
                        );
                      }}
                      suffixIcon={<ExpandIcon />}
                      placeholder="Please select token"
                      options={filteredTokens?.map((c) => ({
                        value: c.asset_id,
                        label: (
                          <div className="coin-select-item large">
                            <img
                              className="coin-icon"
                              src={c.asset_icon || DefaultTokenIcon}
                              alt=""
                            />
                            <div className="coin-name">
                              <span className="symbol">
                                {c.asset_symbol.toUpperCase()}
                              </span>
                              <span className="name">({c.asset_name})</span>
                            </div>
                          </div>
                        ),
                      }))}
                      dropdownRender={(menu) => (
                        <>
                          <Input
                            className="search-token"
                            placeholder="Enter the token symbol"
                            prefix={<SearchOutlined />}
                            onChange={(value) => {
                              const key = value.target.value;
                              if (!key) {
                                setFilteredTokens(allTokens);
                                return;
                              }
                              setFilteredTokens(
                                allTokens.filter((a) =>
                                  a.asset_symbol
                                    .toLowerCase()
                                    .includes(key.toLowerCase())
                                )
                              );
                            }}
                          />
                          <Divider style={{ margin: "8px 0" }} />
                          {menu}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="select network">
                  <div className="select-label">Select Network</div>
                  <div className="value">
                    <Select
                      suffixIcon={<ExpandIcon />}
                      value={selectedNetwork}
                      placeholder="Please select network"
                      onChange={(value) => {
                        setSelectedNetwork(value);
                      }}
                      options={tokenNetworks.map((c) => ({
                        value: c.network,
                        label: (
                          <div className="coin-select-item">
                            <img className="coin-icon" src={c.icon} alt="" />
                            <span className="coin-name">{c.name}</span>
                          </div>
                        ),
                      }))}
                    />
                  </div>
                </div>
              </>
            )}
            {!showAddToken && (
              <div className="add-token" onClick={() => setShowAddToken(true)}>
                <AddIcon className="add-token-icon" />
                Add by Contract Address
              </div>
            )}
            <div className="modal-buttons">
              <Button className="deposit-btn back" onClick={backHandler}>
                Back
              </Button>
              {showAddToken ? (
                <Button className="deposit-btn copy" onClick={addToken}>
                  Confirm
                </Button>
              ) : (
                <Button className="deposit-btn copy" onClick={getAddress}>
                  Next
                </Button>
              )}
            </div>
          </div>
        )}
      </Modal>

      {/* balance */}
      <Modal
        open={balanceOpen}
        onCancel={() => setBalanceOpen(false)}
        closeIcon={<CloseIcon />}
        footer={null}
        width={440}
      >
        <div className="modal-title">Assets</div>
        <div className="balance-modal">
          <div className="total">
            {/* ${totalWealth.toFixed(2).toLocaleString()}
            <div className="label">
              <BalanceIcon />
              <span className="label">MY BALANCE</span>
            </div> */}
          </div>
          <div className="buttons">
            {balanceOperateList.map((op) => (
              <div className="btn" key={op.name} onClick={op.click}>
                <div className="icon">{op.icon}</div>
                <div className="name">{op.name}</div>
              </div>
            ))}
          </div>
          <Tabs defaultActiveKey="assets" centered items={tabList} />
          <div
            className="contact"
            onClick={() => window.open(`https://t.me/JaylenChan`)}
          >
            Meet problems? Please contact us
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Balance;
