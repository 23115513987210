import Onboard from "@web3-onboard/core";
import injectedModule from "@web3-onboard/injected-wallets";
import { ethers } from "ethers";
import { DOMAIN, notify } from ".";
import {
  THEME_KEY,
  TOKEN_KEY,
  authorizationApi,
  baseUrl,
  loginByAddressApi,
} from "../api";
import LogoIcon from "../assets/logoIcon.svg";
import { connect } from "@argent/get-starknet";
const injected = injectedModule();
const darkChrome = matchMedia("(prefers-color-scheme: dark)").matches;
const customTheme = sessionStorage.getItem(THEME_KEY);
const storageTheme: any = customTheme
  ? customTheme
  : darkChrome
  ? "dark"
  : "light";
export const onboard = Onboard({
  wallets: [injected],
  chains: [
    {
      id: "0x1",
      token: "ETH",
      label: "Ethereum",
      rpcUrl: "https://ethereum.publicnode.com",
    },
    {
      id: "0x5",
      token: "GoerliETH",
      label: "ETH Goerli",
      rpcUrl: `https://ethereum-goerli.publicnode.com`,
    },
    {
      id: "0x38",
      token: "BNB",
      label: "Binance",
      icon: "https://famchat.s3.ap-southeast-1.amazonaws.com/asset_icon/network_bsc.jpg",
      rpcUrl: "https://bsc-dataseed.binance.org/",
    },
    {
      id: "0x61",
      token: "TBNB",
      label: "Binance Testnet",
      icon: "https://famchat.s3.ap-southeast-1.amazonaws.com/asset_icon/network_bsc.jpg",
      rpcUrl: "https://bsc-testnet.publicnode.com",
    },
  ],
  connect: {
    autoConnectAllPreviousWallet: true,
    showSidebar: false,
  },
  accountCenter: {
    desktop: {
      position: "bottomRight",
      enabled: false,
      minimal: false,
    },
    mobile: {
      position: "bottomRight",
      enabled: false,
      minimal: false,
    },
  },
  appMetadata: {
    name: "Famlive",
    icon: LogoIcon,
    description: "Listen, Communicate, Explore in Web3 Spaces",
  },
  theme: storageTheme || "dark",
});

const argentConnect = async () => {
  // Let the user pick a wallet (on button click)
  const starknet = await connect({
    include: ["argentX"],
  });

  if (!starknet) {
    throw Error(
      "User rejected wallet selection or silent connect found nothing"
    );
  }

  // (optional) connect the wallet
  await starknet.enable();

  // Check if connection was successful
  if (starknet.isConnected) {
    console.log(starknet);
    (window as any).starknetObject = starknet;
    // If the extension was installed and successfully connected, you have access to a starknet.js Signer object to do all kinds of requests through the user's wallet contract.
    // starknet.account.execute();
    console.log("connected");
  } else {
    // In case the extension wasn't successfully connected you still have access to a starknet.js Provider to read starknet states and sent anonymous transactions
    // starknet.provider.callContract();
    console.log("disconnected");
  }
  return starknet;
};

// connect wallet
export const connectWalletHandler = async (callback?: () => void) => {
  try {
    // connect wallet
    const ethereum = (window as any).ethereum;
    if (!ethereum) {
      notify({
        type: "warning",
        message: "Please install Metamask wallet",
        description: "Please install Metamask wallet",
      });
      return;
    }
    const wallet = ethereum.providers?.[1] || ethereum;
    const accounts = await wallet.request({
      method: "eth_requestAccounts",
    });
    const address = accounts[0];
    // login
    const loginRes = await loginByAddressApi({
      platform_id: address,
      platform: "eth",
      domain: DOMAIN,
    });
    const nonce = loginRes.nonce;
    const info = loginRes.login_msg;
    // signature
    const signature = await (window as any).ethereum.request({
      method: "personal_sign",
      params: [info, address],
    });
    // authorization
    const authRes = await authorizationApi({
      platform_id: address,
      platform: "eth",
      nonce,
      signature,
      domain: DOMAIN,
    });
    const token = authRes.token;
    // save
    localStorage.setItem(TOKEN_KEY, token);
    callback?.();
  } catch (error) {
    console.log(error);
    notify({
      type: "warning",
      message: "Login error",
      description: "Please try again later",
    });
  }
};

export const connectArgentX = async () => {
  try {
    const argentObject = await argentConnect();
    return argentObject;
  } catch (error) {
    console.log(error);
  }
};

// bind wallet
// export const bindWalletHandler = async (callback?: () => void) => {
//   try {
//     const wallets = await onboard.connectWallet();
//     const wallet = wallets[0];
//     const address = wallet?.accounts[0]?.address;
//     if (!address) {
//       return;
//     }
//     const timestamp = Date.now();
//     const chain = "eth";
//     const signRes = await getBindWalletSignMessageApi({
//       timestamp,
//       chain,
//       address,
//       domain: DOMAIN,
//     });
//     const info = signRes.data.msg;
//     const provider = new ethers.BrowserProvider(wallet.provider);
//     const signer = await provider.getSigner();
//     const signature = await signer.signMessage(info);
//     // bind
//     const res = await bindWalletApi({
//       signature,
//       address,
//       timestamp,
//       chain,
//       domain: DOMAIN,
//     });
//     if (res.errno !== 0) {
//       notify({
//         type: "warning",
//         message: "Bind wallet failed",
//         description: res.errmsg,
//       });
//       return;
//     }
//     callback?.();
//   } catch (error: any) {
//     console.log(error);
//   }
// };

// export const bindWalletHandlerStarknet = async (callback?: () => void) => {
//   const starknetObject = (window as any).starknetObject;
//   if (!starknetObject) return;
//   try {
//     const address = starknetObject.account.address;
//     if (!address) {
//       return;
//     }
//     const timestamp = Date.now();
//     const chain = "starknet";
//     const signRes = await getBindWalletSignMessageApi({
//       timestamp,
//       chain,
//       address,
//       domain: DOMAIN,
//     });
//     const info = signRes.data.msg;
//     const provider = starknetObject.account.provider;
//     const signer = starknetObject.account.signer;
//     console.log(JSON.stringify(info));
//     console.log(cairo.felt(info));
//     const signature = await signer.signMessage(JSON.stringify(info));
//     // bind
//     const res = await bindWalletApi({
//       signature,
//       address,
//       timestamp,
//       chain,
//       domain: DOMAIN,
//     });
//     if (res.errno !== 0) {
//       notify({
//         type: "warning",
//         message: "Bind wallet failed",
//         description: res.errmsg,
//       });
//       return;
//     }
//     callback?.();
//   } catch (error: any) {
//     console.log(error);
//   }
// };

// connect twitter
export const connectTwitterHandler = () => {
  const timestamp = Date.now();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const third_channel = params.get("third_channel") || "";
  const url = `${baseUrl}/sns/twitter/request_authorize?operate=login&source=famlive&redirect=${
    window.location.href
  }&t=${timestamp}&third_channel=${third_channel || ""}`;
  console.log(third_channel);

  window.location.href = url;
};

// bind twitter
export const bindTwitterHandler = () => {
  const timestamp = Date.now();
  const token = localStorage.getItem(TOKEN_KEY);
  const url = `${baseUrl}/sns/twitter/request_authorize?token=${token}&operate=bind&source=famlive&redirect=${window.location.href}&t=${timestamp}`;
  window.location.href = url;
};

// support assets
const ChainList = [
  { id: 1, explorer: "https://etherscan.io/" },
  { id: 5, explorer: "https://goerli.etherscan.io/" },
  { id: 56, explorer: "https://bscscan.com/" },
  { id: 97, explorer: "https://testnet.bscscan.com/" },
  { id: 280, explorer: "https://goerli.explorer.zksync.io/" },
  {
    id: 324,
    explorer: "https://explorer.zksync.io/",
  },
].map((i) => ({ ...i, id: `0x${i.id.toString(16)}` }));

// open address by chain id
export const openAddressByChainId = async (address: string) => {
  const chainId = await (window as any).ethereum.request({
    method: "eth_chainId",
  });
  const chain = ChainList.find((i) => chainId === i.id);
  if (chain) {
    window.open(`${chain.explorer}address/${address}`);
  } else {
    window.open(`https://etherscan.io/address/${address}`);
  }
};
export const openStarknetAddress = async (address: string) => {
  const starknetObject = (window as any).starknetObject;
  if (starknetObject.chainId === "SN_MAIN") {
    window.open(`https://starkscan.co/search/${address}`);
  } else {
    window.open(`https://goerli.voyager.online/contract/${address}`);
  }
};
